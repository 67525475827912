<template>
  <div
    class="container size-mobile bg-element-white p-0 min-height-second paket"
    style="font-family: Montserrat"
  >
    <div class="alert alert-primary mt-2" role="alert" @click="cara()" style="border: none !important;border-radius: 0px;margin-bottom:-12px !important;">
      <i class="fa fa-info-circle pr-2"></i><span style="font-size:12px;">Panduan <b>(Klik Disini)</b></span>
    </div>
    <v-dialog/>
    <alert-error ref="ae"/>
    <date-picker v-model="startDate" valueType="format" @change="changeDate"></date-picker>
    <div class="container mt-4" v-if="isLoading">
      <content-placeholders :rounded="true">
        <content-placeholders-text :lines="1" />
        <content-placeholders-img />
        <content-placeholders-text :lines="2" />
        <content-placeholders-text :lines="1" />
      </content-placeholders>
    </div>
    <div v-else>
      <div class="container" v-if="datas.jml_destination == 0">
        <div class="alert alert-danger mb-0" role="alert">
        <i class="fa fa-info-circle"></i>&nbsp;<span style="font-size:12px;">Data titik belum tersedia, hubungi <b>Korlap</b></span>
      </div>
      </div>
      <div v-if="datas.data.length > 0" class="table-responsive" style="font-size: 12px;font-family:roboto">
        <table v-if="datas" class="table table-bordered table-hover">
          <thead>
            <tr>
              <th scope="col">Nama</th>
              <th scope="col">Jml.Titik</th>
              <th scope="col">jml.terupload</th>
              <th scope="col">Selesai</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(data, index) in datas.data"
              v-bind:key="index" 
              @click="(data.v_bisa_diklik == 0 ? '' :showModal(data,index+1))"
              :class="(data.v_bisa_diklik == 0 ? 'table-active' :'')">
              <td>Patroli {{index+1}}</td>
              <td>{{data.jml_titik}}</td>
              <td>{{data.jml_terupload}}</td>
              <td> 
                <label class="badge mb-0" :class="(data.is_finish == 1 ? 'badge-success' : 'badge-danger')">{{data.is_finish == 1 ? 'Sudah' : 'Belum'}}</label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="container" v-else>
        <div class="text-date d-flex bg-highlight justify-content-center bg-light rounded p-3"> Tidak ada data patroli ditanggal ini</div>
      </div>
      
     
      <div class="d-flex"> 
        <span class="float" @click="load()" style="background-color:#e0e0e0;color:#000;">
          <i class="fa fa-refresh my-float"></i>
        </span>
      </div>
      <!-- Modal Detail Patroli -->
      <modal v-if="isModalVisible" @close="closeModal" style="z-index: 99">
        <!-- Override Modal Header -->
        <template v-slot:header><b style="color: #2b80ac">Update Data Patroli</b></template>
        <!-- Override Modal body -->
        <template v-slot:body>
          <h5>{{patroli}}</h5>
          <!-- <h5>{{idLokasi}}</h5> -->
          <form id="edit-form" class="form-horizontal" method="POST" @submit.prevent="update(idLokasi)" enctype="multipart/form-data"> 
          <div class="alert alert-primary" role="alert" v-if="idLokasi">
            <i class="fa fa-info-circle"></i>&nbsp;<span style="font-size:12px;">Menu upload foto akan muncul setelah data lokasi disimpan</span>
          </div>
            <fieldset>
              <img style="right: 2rem;position: absolute;" src="../../assets/img/arrow-down.png"/>
              <legend class="legend-input">Lokasi Cek</legend>
              <select class="form-control" v-model="idLokasi" @change="changeLokasi($event.target.value)">
                  <option value="">-- Pilih --</option>
                  <option :value="data.id" v-for="(data, index) in dataLokasi" :key="index">{{data.nama_destinasi}}</option>
              </select>
            </fieldset>

            <!-- Keterangan dan foto muncul jika state idLokasi terisi -->
            <div v-if="idLokasi">
              <fieldset>
                <legend class="legend-input">Keterangan</legend>
                <textarea name="tujuan" class="form-control" autocomplete="off" v-model="dataTitikLokasi.keterangan" style="resize: none;"></textarea> 
              </fieldset>  
              
              <button class="btn-blue mt-3" style="width:100%" :class="[(isLoadingUpload || isSaved? 'disable' : '')]" type="submit">
                <span class="d-flex align-items-center justify-content-center" v-if="isLoadingUpload">
                    <div class="spinner-border text-primary mr-3 spinner-border-sm" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  Sedang Menyimpan...
                </span>
                <span v-else>Simpan</span>
              </button>
              
              <!-- Foto --> 
              <div v-if="status">
                <div class="text-4 mt-3 mb-3">Bukti Foto</div>
                <div>
                  <content-placeholders :rounded="true" v-if="isLoadingImg">
                    <content-placeholders-img />
                  </content-placeholders>
                  <div
                      v-else
                      is="todo-item"
                      v-for="(todo, index) in todos"
                      v-bind:key="todo.id"
                      v-bind:img="(todo.file_url ? todo.file_url: require('../../assets/img/upload.png'))"
                      v-bind:close="require('../../assets/img/clear-icon.png')" 
                      v-on:remove="deleteImg(todo.id,index)"
                      v-on:open="modalShowImg(index,todo.file_url ? 'update' : 'new')" >
                  </div> 

                  <!-- Button append -->
                  <div class="d-flex align-items-center mb-3" @click="addNewImage" style="cursor:pointer">
                    <img src="../../assets/img/plus-image.png" style="width:25px;"/>
                    <span class="pl-2 add-foto">Tambah Foto</span>
                  </div> 
                </div>
              </div> 
              <!-- <span class="btn btn-danger mt-3" style="width:100%" @click="modalDelete(dataModal.id)" :class="{'disable': isSubmit}" type="submit">Hapus</span>  -->
            </div>
            
          </form>
        </template>
      </modal>
      <!-- Modal Img -->
      <modal v-show="isModalVisibleImg" @close="closeModalImg">
        <!-- Override Modal Header -->
        <template v-slot:header>Unggah Foto</template>
        
        <!-- Override Modal body -->
        <template v-slot:body>
          <form class="uploadFile d-flex flex-column justify-content-center"  @submit.prevent="uploadSubmit()" enctype="multipart/form-data" method="post" style="position:absolute;bottom:0;width:95%;height:100%" >
              <div id="preview" class="d-flex align-items-center justify-content-center mb-2" style="height:80%;">
                <img v-if="imgUploaded" :src="imgUploaded" style="object-fit: contain;width: 100%;height: 100%;text-align:center"/>
              </div>
              <a class="btn btn-daftar" href="" style="width: auto !important;">
                  <span class="" style="float:left;margin-right:-15px;margin-top:-3px"><img src="../../assets/img/cam.png" alt="Attach" style="width:25px;"></span>
                  Ambil Gambar
                  <input id="file" name="file" type='file' class="attach" accept="image/*" capture  @change="onFileChange"/>
              </a>
              <button v-if="imgUploaded" :disabled="isLoadingUpload" id="submit" class="btn btn-login mt-1" style="width: auto !important;">
                <span class="d-flex align-items-center justify-content-center" v-if="isLoadingUpload">
                    <div class="spinner-border text-primary mr-3 spinner-border-sm" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  Sedang Upload...
                </span>
                <span v-else>Upload</span>
                
              </button>
              <div class="progress mt-1">
                        <!-- PROGRESS BAR DENGAN VALUE NYA KITA DAPATKAN DARI VARIABLE progressBar -->
                <div class="progress-bar" role="progressbar" 
                    :style="{width: progressBar + '%'}" 
                    :aria-valuenow="progressBar" 
                    aria-valuemin="0" 
                    aria-valuemax="100"></div>
              </div>
          </form>
        </template>
      </modal>
      <alert ref="c"/>
    </div>
  </div>
</template>
 
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";   

// import $ from "jquery";
// import _ from "lodash"; 
import Api from "../../Api";
import Vue from 'vue';
import alertError from "../../components/AlertError.vue";
import alert from "../../components/Alert.vue";

Vue.component('todo-item', {
  template: '\
    <div class="d-flex bd-highlight flex-column">\
      <img v-bind:src="img" class="foto-absen mb-3" v-on:click="$emit(\'open\')"/>\
      <img v-bind:src="close" v-on:click="$emit(\'remove\')" class="close-btn"/>\
    </div>\
  ',
  props: ['img','close','isStore']
})
export default {
  name: "CekLokasi",
  components: {
    DatePicker,
    modal: () => import("../../components/Modal.vue"),
    alert,
    alertError, 
  },
  data() {
    var currentdate = new Date(); 
    var datetime = currentdate.getFullYear() + "-"
            + ('0' + (currentdate.getMonth()+1)).slice(-2)  + "-" 
            + ('0' + (currentdate.getDate())).slice(-2)
    return {
      todos: [
        {
          id: 1,
          img: require('../../assets/img/upload.png'),
        },
      ],
      dataLokasi:'',
      dataTitikLokasi:[],
      nextImageId: 2, 
      startDate:datetime,
      isLoading: true,
      datas: [],
      isLoadingUpload:false,
      total: 0,
      dataModal: [],
      dataImg: [],
      isModalVisible: false,
      isModalVisibleImg: false,
      isLoadingImg: false,
      isSaved:false,
      updatedContent: "",
      token: '',
      progressBar:0,
      myData: '',
      idLokasi:'',
      indexImg: '',
      status: '',
      patroli:'',
      file_upload:'',
      attachment_id:'',
      imgUploaded:'',
      isSubmit: false,
      img: require('../../assets/img/upload.png'),
      dataFiltered: 0,
      typeSubmit: ''
    };
  },

  async mounted() {
    this.token = JSON.parse(localStorage.getItem('token'))
    this.myData = JSON.parse(localStorage.getItem('cred'))
    this.load();
    // console.log(JSON.parse(localStorage.getItem('cred')));
  },

  methods: { 
    // change lokasi
    async changeLokasi(event){
      console.log(event)
      this.isSaved = false
      if(event !== ''){
        await Api.get("satpam/tour/detail-titik/"+this.dataModal.id+"/"+event+"/"+this.myData.id,{
          headers: {Authorization: 'Bearer ' + this.token}
        })
        .then((response) => {
            // console.log(response) 
            if(response.data.message ==='Data sudah tersedia'){
              this.todos = response.data.attachment
              // console.log(response.data.attachment)
              this.status = response.data.status;
              this.dataTitikLokasi = response.data.data
              return true
            }else{
              this.status = '';
              this.dataTitikLokasi=[];
              // this.todos=[]
            }
            // console.log(this.dataTitikLokasi)
            // this.dataLokasi = response.data.data_destination;
            // console.log(this.datas) 
          })
          .catch((error) => {
            console.log(error);
            this.$refs.ae.showAlert()
          });
      }
      this.loadImg()
      // console.log(id)
      // this.idLokasi = id
      // console.log([event.target.value,this.dataModal])
    },
    // Detail patroli
    async detailPatroli(id){
      await Api.get("satpam/tour/detail/"+id,{
        headers: {Authorization: 'Bearer ' + this.token}
      })
      .then((response) => {
          // console.log(response)
          this.dataLokasi = response.data.data_destination;
          // console.log(this.datas) 
        })
        .catch((error) => {
          console.log(error);
          this.$refs.ae.showAlert()
        });
    },
    // create patroli
    async createPatroli(index,date){ 
      const data = {
        user_id: this.myData.id,
        workplace_id: this.myData.id_tempat_kerja,
        created_at: date
      }
      // this.isLoading = true;
      await Api.post("satpam/tour/create-tour",data,{
        headers: {Authorization: 'Bearer ' + this.token}
      })
      .then((response) => {
          this.dataModal = response.data.data
          this.showModal(this.dataModal,index)
          // console.log(response)
        })
        .catch((error) => {
          console.log(error);
          this.$refs.ae.showAlert()
        });
      this.isLoading = false;
      // console.log(this.startDate)
    },
    // Filter by date
    async changeDate(){
      this.isLoading = true;
      await Api.get("satpam/tour/"+this.myData.id_tempat_kerja+'/'+this.myData.id+'/'+this.startDate,{
        headers: {Authorization: 'Bearer ' + this.token}
      })
      .then((response) => {
          console.log(response)
          this.datas = response.data;
          // console.log(this.datas) 
        })
        .catch((error) => {
          console.log(error);
          this.$refs.ae.showAlert()
        });
      this.isLoading = false;
      // console.log(this.startDate)
    },
    async load() {
      // console.log(this.startDate)
      this.isLoading = true
      this.changeDate()
      // Get Data Lokasi
      
    },
    // Append foto baru
    addNewImage: function () {
      this.todos.push({
        id: this.nextImageId++,
        img: this.img
      })
      this.newTodoText = '' 
    }, 
    // Update data lokasi
    async update(idLokasi){
      this.isLoadingUpload = true
      const data = {
        user_id: this.myData.id,
        satpam_destination_id: idLokasi,
        satpam_tour_id: this.dataModal.id,
        keterangan: this.dataTitikLokasi.keterangan, 
      }

      await Api.post("satpam/tour/simpan-laporan",data,{
        headers: {Authorization: 'Bearer ' + this.token}
      })
      .then(() => {
        this.isSaved = true;
        // console.log(response)
        this.changeLokasi(idLokasi)
        // this.closeModal()
        this.load()
        this.$refs.c.showAlert('Sukses','Berhasil Update Data Patroli')
      })
      .catch((error) => {

        console.log(error);
        this.$refs.ae.showAlert()
      });
      this.isLoadingUpload = false 
    }, 
    // Show modal data per lokasi
    showModal(data,index) {
      this.patroli = 'Patroli '+index
      // console.log(this.dataModal)
      if(data.id === null){
        this.createPatroli(index,this.startDate)
        return true;
      }
      this.dataModal = data; 
      this.detailPatroli(data.id)
      // this.updatedContent = data.pengirim
      this.isModalVisible = true;
    },
    // Modal show img per id
    modalShowImg(id,type) {
      this.progressBar = 0
      this.typeSubmit = type
      this.imgUploaded = this.todos[id].file_url
      this.attachment_id = this.todos[id].id
      this.indexImg = id
      // this.idImg = id;
      this.isModalVisibleImg = true 
    },
    // close modal update
    closeModal() {
      this.load()
      this.dataLokasi=''
      this.idLokasi=''
      this.file_upload = ''
      this.dataTitikLokasi=[]
      this.isSaved= false
      this.isModalVisible = false;
      this.isLoadingUpload = false
      return true
    },
    // close modal img
    closeModalImg() {
      this.isLoadingUpload = false
      
      this.file_upload = ''
      // this.changeLokasi(this.idLokasi)
      this.isModalVisibleImg = false;
      return true
    },
    // delete data lokasi
    modalDelete(id){
      console.log(id)
      this.$modal.show('dialog', {
        title: 'Yakin Hapus Data lokasi ?',
        text: 'Data yang telah dihapus tidak dapat dikembalikan',
        buttons: [
          {
            title: 'Tutup',
            handler: () => {
              this.$modal.hide('dialog')
              // this.$router.push(this.$route.query.redirect || '/')
            }
          }, 
          {
            title: 'Hapus',
            handler: () => {
              Api.delete("satpam/cek-lokasi/"+id,{
                headers: {Authorization: 'Bearer ' + this.token}
              })
              .then(() => {
                this.closeModal()
                this.$modal.hide('dialog')
                this.load()
              })
              .catch((error) => {

                console.log(error);
                this.$refs.ae.showAlert()
              });
              // this.$router.push(this.$route.query.redirect || '/')
            }
          }, 
        ]
      })
    },
    // Looping img dalam modal
    async loadImg() { 
        // console.log(this.todos)
      // this.isLoadingImg = true
      // // Get Data Lokasi
      // await Api.get("satpam/cek-lokasi/detail/"+id,{
      //   headers: {Authorization: 'Bearer ' + this.token}
      // })
      // .then((response) => {
      //   // this.dataImg = response.data.data
      //   this.todos = response.data.data
      //   this.isLoadingImg = false
      //     // console.log(this.datas)
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //     this.$refs.ae.showAlert()
      //   });
    },
    // onchange ketika gambar dipilih
    onFileChange(e) {
      const file = e.target.files[0]; 
      this.imgUploaded = URL.createObjectURL(file);
      this.file_upload = e.target.files[0] 
    },
    //MENGIRIM FILE UNTUK DI-UPLOAD
    async uploadSubmit() {
        if(!this.file_upload){
          this.closeModalImg();
          return true
        }
        this.isLoadingUpload = true

        // Update
        if(this.typeSubmit == 'update'){
          let formData = new FormData();
          formData.append('file_upload', this.file_upload)
          formData.append('attachment_id', this.attachment_id)
          
          await Api.post('satpam/tour/upload-foto-update', formData, {
              headers: {
                Authorization: 'Bearer ' + this.token, 
                'Content-Type': 'multipart/form-data'
                
              },
              onUploadProgress: function( progressEvent ) {
                //DATA TERSEBUT AKAN DI ASSIGN KE VARIABLE progressBar
                this.progressBar = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
            }.bind(this)
          })
          .then((response) => {
              this.todos[this.indexImg].file_url = response.data.data.file_url 
              this.changeLokasi(this.idLokasi)
              // Jika berhasil upload gambar
              this.closeModalImg()
              this.$refs.c.showAlert('Sukses','Foto berhasil diupload')
              this.isLoadingUpload = false
          })  
        // New
        }else{
          let formData = new FormData();
          formData.append('file_upload', this.file_upload)
          formData.append('satpam_tour_detail_id', this.dataTitikLokasi.id)
          formData.append('jenis_upload', 'satpam_tour_detail')
          
          await Api.post('satpam/tour/upload-foto-new', formData, {
              headers: {
                Authorization: 'Bearer ' + this.token, 
                'Content-Type': 'multipart/form-data'
                
              },
              onUploadProgress: function( progressEvent ) {
                //DATA TERSEBUT AKAN DI ASSIGN KE VARIABLE progressBar
                this.progressBar = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
            }.bind(this)
          })
          .then((response) => {
              console.log(response)
              // this.loadImg(this.dataModal.id)
              this.changeLokasi(this.idLokasi)
              this.closeModalImg()
              
              // Jika berhasil upload gambar
              this.$refs.c.showAlert('Sukses','Foto berhasil diupload')
              this.isLoadingUpload = false
          })
        }
        
    },
    // Hapus Foto
    async deleteImg(id,index){
      const data = {
        attachment_id: id,
      }
      await Api.post("satpam/cek-lokasi/delete-foto",data,{
        headers: {
          Authorization: 'Bearer ' + this.token,
          }
      })
      .then((response) => {
        console.log(response)
        this.todos.splice(index, 1)
      })
      .catch((error) => {

        console.log(error);
        this.$refs.ae.showAlert()
      });
    },
    cara(){
      this.$refs.c.showAlert('Panduan','1. <b>Kolom berwarna abu abu</b> artinya, Patroli belum bisa diisi, anda harus menyelesaikan patroli sebelumnya<b>(Kolom warna putih)</b> sesuai jml titik <b>')
    }
  }, 
};
</script> 
<style>
.close-btn{
  width: 20px;
  position: absolute;
  right: 26px;
  margin-top: 10px;
  cursor:pointer;
}
  /* th,td{
    padding-left:3px;
    padding-right:3px;
  } */
</style>